import { ReactElement, useEffect, useState, useCallback, useMemo } from 'react';
import { CartCounterModal } from '../CartCounterModal';
import * as S from './CartCounter.styles';
import { Button } from '@/common/ui';
import { useCartContext } from '@/entities/Cart/app/CartContext';
import { useRouter } from 'next/router';

const SEC_IN_MIN = 60;
const SHOW_MODAL = 60;
const LAST_SECOND = 1;

type TCartCounterProps = {
    isStoped?: boolean;
};

const CartCounter = ({ isStoped }: TCartCounterProps): ReactElement => {
    const [modalOpen, setModalOpen] = useState(false);
    const { push, asPath } = useRouter();
    const { expireTime, updateTimer, setExpired } = useCartContext();
    const [time, setTime] = useState(0);
    const isCheckoutPages = useMemo(() => asPath === '/cart/' || asPath === '/checkout/', [asPath]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(() => {
                const currentTime = new Date().getTime();
                const newTime = Math.floor((expireTime - currentTime) / 1000);

                return newTime > 0 ? newTime : 0;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [expireTime]);

    const openCheckout = useCallback(async () => {
        await updateTimer(time);
        push('/checkout/');
    }, [push, updateTimer, time]);
    const onExtend = () => updateTimer(time);

    if (time === SHOW_MODAL && !modalOpen) {
        setModalOpen(true);
    }

    if (time === LAST_SECOND && !isStoped) {
        setExpired();
        setModalOpen(false);
    }

    const minutes = `${~~(time / SEC_IN_MIN)}`.padStart(2, '0');
    const seconds = `${time % SEC_IN_MIN}`.padStart(2, '0');

    const timeLine = `${minutes}:${seconds}`;
    const showBtn = time < SHOW_MODAL;

    return (
        <S.CounterWrapper isCart={isCheckoutPages} data-test-id="Counter">
            {time > 0 && (
                <>
                    {isCheckoutPages && (
                        <>
                            <S.CounterText>
                                Tickets are being held for: &nbsp;
                                <S.Counter>{timeLine}</S.Counter>
                            </S.CounterText>
                            {showBtn && (
                                <S.ButtonWrapper>
                                    <Button variant="primary" onClick={onExtend}>
                                        <S.IconWhiteCross />{' '}
                                        <S.ExtendMinutes>Extend time by 5 minutes</S.ExtendMinutes>
                                    </Button>
                                </S.ButtonWrapper>
                            )}
                        </>
                    )}
                    {modalOpen && (
                        <CartCounterModal
                            onClose={() => setModalOpen(false)}
                            onExtend={onExtend}
                            onCheckout={openCheckout}
                            duration={timeLine}
                        />
                    )}
                </>
            )}
        </S.CounterWrapper>
    );
};

export { CartCounter };
