import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { FlexPresets } from '@/styles/Themes/Presets';
import { Palette } from '@/styles/Themes/Palette';
import { WhiteCross } from '@/shared/images/icons/WhiteCross';

export const CounterWrapper = styled.div.attrs(({ isCart }: { isCart: boolean }) => ({ isCart }))`
    width: 100vw;
    min-height: ${({ isCart }) => (isCart ? '130px' : 0)};
    padding: ${({ isCart }) => (isCart ? '0 18px 18px' : 0)};

    ${Screen.ipad} {
        min-height: ${({ isCart }) => (isCart ? '140px' : 0)};
    }
`;

export const CounterText = styled.div`
    color: ${Palette.gray.gray3};
    display: flex;
    justify-content: center;
    ${Text.Full.large.regular};
    padding: 92px 6px 0;

    ${Screen.ipad} {
        padding: 100px 6px 0;
    }

    ${Screen.desktop} {
        padding: 100px 0 0;
    }
`;

export const Counter = styled.div`
    color: ${Palette.state.error};
    ${Text.Full.large.medium};
    width: 40px;
`;

export const ButtonWrapper = styled.div`
    ${FlexPresets.flexRowCenter};
    width: 270px;
    height: 36px;
    cursor: pointer;
    margin: 24px auto 6px;
    ${Text.Mobile.medium.medium};

    ${Screen.desktop} {
        ${Text.Desktop.large.medium};
    }
`;

export const IconWhiteCross = styled(WhiteCross)`
    height: 8px;
    width: 9px;

    ${Screen.desktop} {
        height: 8px;
        width: 7px;
    }
`;

export const ExtendMinutes = styled.div`
    margin: 0 10px;
`;
