import { Button } from '@/common/ui';
import * as S from './CartCounterModal.styles';

type CartCounterModalProps = {
    duration: string;
    onClose: () => void;
    onExtend: () => void;
    onCheckout: () => void;
};

const CartCounterModal = ({ duration, onClose, onExtend, onCheckout }: CartCounterModalProps) => {
    const handleExtend = () => {
        onExtend();
        onClose();
    };

    const handleCheckout = () => {
        onCheckout();
        onClose();
    };

    const mins = duration.split(':')[0];
    const secs = duration.split(':')[1];

    return (
        <S.ModalWrapper>
            <S.ModalContent>
                <S.CloseButton onClick={() => onClose()}>
                    <S.CloseButtonWithIcon />
                </S.CloseButton>
                <S.ModalBody>
                    <S.IconCart />
                    <S.Title>Your Tickets are being held for:</S.Title>
                    <S.ModalTime>
                        <S.ModalTimeText>
                            <S.ModalTimes>00</S.ModalTimes>
                            <S.ModalText>hours</S.ModalText>
                        </S.ModalTimeText>{' '}
                        :
                        <S.ModalTimeText>
                            <S.ModalTimes>{mins}</S.ModalTimes>
                            <S.ModalText>minutes</S.ModalText>
                        </S.ModalTimeText>{' '}
                        :
                        <S.ModalTimeText>
                            <S.ModalTimes>{secs}</S.ModalTimes>
                            <S.ModalText>seconds</S.ModalText>
                        </S.ModalTimeText>
                    </S.ModalTime>
                    {/* <S.ModalTimeText>
                        
                        
                        
                    </S.ModalTimeText> */}
                </S.ModalBody>
                <S.Buttons>
                    <S.BtnCancelWrapper>
                        <Button variant={'transparent'} onClick={handleExtend}>
                            Extend by 5 minutes
                        </Button>
                    </S.BtnCancelWrapper>
                    <S.BtnOkWrapper>
                        <Button variant={'primary'} onClick={handleCheckout}>
                            {' '}
                            Checkout now
                        </Button>
                    </S.BtnOkWrapper>
                </S.Buttons>
            </S.ModalContent>
        </S.ModalWrapper>
    );
};

export { CartCounterModal };
