export const WhiteCross = () => {
    return (
        <svg
            width="9"
            height="10"
            viewBox="0 0 9 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64194 9.09989C7.8294 9.30003 8.13333 9.30003 8.32078 9.09989C8.50824 8.89975 8.50824 8.57526 8.32078 8.37513L5.17883 5.02061L8.35939 1.62487C8.54685 1.42473 8.54685 1.10024 8.35939 0.900104C8.17194 0.699965 7.86801 0.699965 7.68055 0.900104L4.49999 4.29584L1.31942 0.900101C1.13197 0.699963 0.828041 0.699962 0.640584 0.900101C0.453128 1.10024 0.453128 1.42473 0.640584 1.62487L3.82115 5.02061L0.679191 8.37513C0.491735 8.57527 0.491735 8.89976 0.679191 9.0999C0.866648 9.30003 1.17057 9.30003 1.35803 9.0999L4.49999 5.74537L7.64194 9.09989Z"
                fill="white"
            />
        </svg>
    );
};
