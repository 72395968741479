import { IActivityItem } from '@/common/service/api/Activity/Activity.domain';

export interface IAttributes {
    id: number;
    value: string;
    group?: string;
}

export const attributes: IAttributes[] = [
    {
        id: 1,
        value: 'Private Trip',
    },
    {
        id: 2,
        value: 'Swim Friendly',
    },
    {
        id: 3,
        value: 'Cruise Only',
    },
    {
        id: 4,
        value: 'Restroom',
    },
    {
        id: 5,
        value: 'Captain Included',
    },
    {
        id: 22,
        value: '1-6 passenger',
        group: 'count',
    },
    {
        id: 6,
        value: '7-12 passenger',
        group: 'count',
    },
    {
        id: 7,
        value: '13-20 passenger',
        group: 'count',
    },
    {
        id: 8,
        value: '21-40 passenger',
        group: 'count',
    },
    {
        id: 9,
        value: '41-75 passenger',
        group: 'count',
    },
    {
        id: 10,
        value: '75+ passenger',
        group: 'count',
    },
    {
        id: 11,
        value: 'Waterslide',
    },
    {
        id: 12,
        value: 'Half Day Trip (if duration 3-6 hour)',
        group: 'duration',
    },
    {
        id: 13,
        value: 'All Day Trip (if duration 7-12 hour)',
        group: 'duration',
    },
    // {
    //     id: 14,
    //     value: 'Alcohol Friendly',
    // },
    {
        id: 15,
        value: 'Kid Friendly',
    },
    {
        id: 16,
        value: 'Pet Friendly',
    },
    {
        id: 17,
        value: 'Water Toys Available',
    },
    {
        id: 18,
        value: 'Handicap Accessible',
    },
    {
        id: 19,
        value: 'Morning Departure (5am-11:59am)',
        group: 'time',
    },
    {
        id: 20,
        value: 'Afternoon Departure (12pm-4:49pm)',
        group: 'time',
    },
    {
        id: 21,
        value: 'Evening Departure (4:49-11:59pm)',
        group: 'time',
    },
    {
        id: 30,
        value: 'Hourly Rental',
    },
    {
        id: 23,
        value: 'Inshore/Bay',
    },
    {
        id: 24,
        value: 'Offshore/Deep Sea',
    },
    {
        id: 25,
        value: 'Air Controlled Cabin',
    },
    {
        id: 26,
        value: 'Food/Drink Available',
    },
    {
        id: 27,
        value: 'Live Music',
    },
    {
        id: 28,
        value: 'Kid Friendly (Ages 7+)',
    },
    {
        id: 29,
        value: 'Kid Friendly (All Ages)',
    },
];

const getInitialAttrArrays = (attrs: number[]) =>
    Object.values(
        attributes
            .filter((i) => attrs.includes(i.id))
            .reduce(
                (
                    acc: {
                        [key: string]: number[];
                    },
                    curr
                ) => {
                    const key = curr.group || curr.value;
                    return {
                        ...acc,
                        [key]: !acc[key] ? [curr.id] : [...acc[key], curr.id],
                    };
                },
                {}
            )
    );

const compareAttributes = (attrs: number[], tripAttrs: number[]): boolean =>
    getInitialAttrArrays(attrs).every((i) => i.some((f) => tripAttrs.includes(f)));

export const parseAttr = (attr: string): number[] => {
    try {
        return JSON.parse(attr);
    } catch (error) {
        return [];
    }
};

export const filterByAttribution = ({
    trips,
    attributes,
}: {
    trips: IActivityItem[];
    attributes: number[];
}): IActivityItem[] => {
    if (!attributes.length) {
        return trips;
    }
    return trips.filter((i) => {
        if (i.attributes) {
            return compareAttributes(attributes, parseAttr(i.attributes));
        }
        return false;
    });
};
