import { useRouter } from 'next/router';
import { memo, ReactNode, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

import { trackPageView } from '@/common/app/utils/iterable';
import { Footer } from '@/shared/Footer/ui/Footer';
import { useCartContext } from '@/entities/Cart/app/CartContext';
import { IBreadcrumbsItem } from '@/shared/Breadcrumbs/domain/Breadcrumbs.domain';
import { Breadcrumbs } from '@/shared/Breadcrumbs/ui';
import { GoogleAuth } from '@/shared/GoogleAuth/ui/GoogleAuth';
import { PromoHeader } from '@/shared/Header/ui/PromoHeader';
import { getHeroImageData } from '../service/Creators';
import HeroImage from './HeroImage/HeroImage';
import { MainContent, Wrapper } from './Layout.styles';
import { checkAndSavePromo } from '../service/checkAndSavePromo';
import { Notification } from '@/common/ui';
import CookieNotification from '@/shared/CookieNotification';

import { TChatProps } from '@/entities/Chat/ui/Chat';
import {
    createAffCode,
    affiliatesAddClick,
    getAffData,
    isAffDataEqual,
    saveAffData,
} from '@/common/app/utils/affiliates';
import { useCRSParams } from '@/common/service/hooks/useCRSParams';
import { useAppContext } from '@/common/app/contexts';
import { DiscountWidget } from '@/shared/DiscountWidget/DiscountWidget';
import { CartCounter } from '@/screens/CartPage/CartCounter';

const AmazonChat = dynamic<TChatProps>(
    () => import('@/entities/Chat/ui/Chat').then((module) => module.default),
    {
        ssr: false,
    }
);

type TLayoutProps = {
    children: ReactNode;
    breadcrumbs?: IBreadcrumbsItem[];
    noFooter?: boolean;
    noSearch?: boolean;
    images?: string[];
    TitleName?: string;
    SubTitle?: string;
    totalActivities?: number;
    isHomePage?: boolean;
    withMobileHeroImage?: boolean;
};

const LayoutContainer = ({
    children,
    breadcrumbs,
    noFooter,
    images,
    TitleName,
    SubTitle,
    totalActivities,
    isHomePage,
    withMobileHeroImage,
    noSearch,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
TLayoutProps) => {
    const { pathname, query, isReady, asPath } = useRouter();
    const [open, setOpen] = useState<boolean>(false);
    const { runToast, expireTime } = useCartContext();
    const { isOpenPromo } = useAppContext();
    const [isChatLoaded, setIsChatLoaded] = useState(false);
    const purePath = asPath.split('?')[0];

    const showChatBtn = [
        '/[ActivitySlug]/details/[ActivityId]',
        '/[...contentSlug]',
        '/destination/[state]/[destination]/things-to-do',
        '/destination/[state]/[destination]/things-to-do/[category]',
    ].includes(pathname);
    // const isPageWithoutPromo = ['/checkout', '/order/voucher', '/order/access'].includes(pathname);

    const isPageWithPromo =
        isHomePage ||
        ['/blog', '/tour', '/details', '/things-to-do', '/destination', '/landmarks'].some((i) =>
            pathname.includes(i)
        );

    const isPageWithWidget =
        isHomePage ||
        [
            '/tour/[ActivityId]',
            '/[ActivitySlug]/details/[ActivityId]',
            '/destination',
            '/destination/[state]/[destination]',
            '/destination/[state]/[destination]/things-to-do',
            '/destination/[state]/[destination]/things-to-do/[category]',
            '/blog',
            '/blog/[...blogSlug]',
        ].includes(pathname);

    const destinationName =
        query.slug && typeof query.slug !== 'string' && query.slug[1] ? query.slug[1] : '';

    const heroImage = getHeroImageData({
        pathname: pathname,
        destinationName: TitleName ?? destinationName,
        subTitle: SubTitle ?? '',
        images: images || [],
        totalActivities: totalActivities || 0,
    });
    const transparent = Boolean(heroImage);

    useEffect(() => {
        if (isReady) {
            trackPageView();
        }
    }, [purePath, isReady]);

    useEffect(() => {
        if (isReady && query.promo && !pathname.match(/details|things-to-do/)) {
            checkAndSavePromo(query.promo, runToast);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReady, query.promo, pathname]);

    useEffect(() => {
        if (query.aff && typeof query.aff === 'string') {
            const affCode = createAffCode(query.aff, asPath);
            const oldAffData = getAffData();

            const { utm_type, utm_campaign, utm_source } = query;
            const affParams = {
                ...(utm_type && { utm_type: utm_type.toString() }),
                ...(utm_campaign && { utm_campaign: utm_campaign.toString() }),
                ...(utm_source && { utm_source: utm_source.toString() }),
            };

            const affData = {
                affCode,
                ...affParams,
            };

            if (!isAffDataEqual(affData, oldAffData)) {
                saveAffData(affData);
                affiliatesAddClick(affCode, affParams);
            }
        }
    }, [asPath, query]);

    useCRSParams();

    return (
        <Wrapper id="Layout" withPromo={isOpenPromo && isPageWithPromo}>
            {!!expireTime && <CartCounter />}
            <PromoHeader
                pathname={pathname}
                scrollY={heroImage?.scrollY}
                transparent={transparent}
                open={open}
                isHomepage={!!isHomePage || (!!withMobileHeroImage && !noSearch)}
                setOpen={setOpen}
            />
            <MainContent
                withDesktopHeroImage={!!heroImage}
                withMobileHeroImage={!!withMobileHeroImage || !!isHomePage}
            >
                {heroImage && (
                    <HeroImage
                        {...heroImage}
                        withMobileHeroImage={!!withMobileHeroImage || !!isHomePage}
                        isHomePage={!!isHomePage}
                        noSearch={noSearch}
                    />
                )}
                {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
                {children}
            </MainContent>
            <Notification />
            {(showChatBtn || open) && (
                <AmazonChat
                    open={open}
                    showChatBtn={showChatBtn}
                    setOpen={setOpen}
                    isLoad={isChatLoaded}
                    setIsLoad={setIsChatLoaded}
                />
            )}

            {isPageWithWidget && <DiscountWidget />}

            <GoogleAuth />
            {!noFooter && <Footer />}
            <CookieNotification />
        </Wrapper>
    );
};
export const Layout = memo(LayoutContainer);
export default memo(Layout);
