import Screen from '@/styles/Themes/Screen';
import styled, { DefaultTheme } from 'styled-components';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';
import { CloseIcon } from '@/shared/images/icons/CloseIcon';
import { SandWatch } from '@/shared/images/icons/SandWatch';
import Palette from '@/styles/Themes/Palette';

export const ModalWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 6;
`;

export const ModalContent = styled.div`
    position: relative;
    background-color: ${cssVars.bg_white_dark3};
    border-radius: ${radius.small};
    box-shadow: ${cssVars.shadow_modal};
    width: 340px;
    padding: 20px 30px 30px;
    height: 380px;

    ${Screen.ipad} {
        width: 550px;
        height: 410px;
        padding: 30px 40px 40px;
    }

    ${Screen.desktop} {
        width: 659px;
        height: 415px;
        padding: 45px 49px 50px;
    }
`;

export const Title = styled.div`
    display: flex;
    justify-content: center;
    padding: 23px 0 17px;
    ${Text.Full.large.medium};
    color: ${cssVars.fg_gray1_white};

    ${Screen.ipad} {
        padding: 26px 92px 8px;
    }

    ${Screen.desktop} {
        margin: 28px 0 11px;
        padding: 0 60px;
    }
`;

export const ModalBody = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    ${Text.Full.medium.regular};
    color: ${Palette.black};
    box-sizing: border-box;

    ${Screen.desktop} {
        padding: 0;
    }

    > svg {
        margin: 0 auto;

        ${Screen.ipad} {
            width: 100px;
            height: 100px;
        }

        ${Screen.desktop} {
            width: 85px;
            height: 85px;
        }
    }
`;

export const Buttons = styled.div`
    display: flex;
    padding: 24px 0 0;
    flex-direction: column;
    justify-content: space-between;

    ${Screen.ipad} {
        flex-direction: row;
        align-items: end;
    }

    ${Screen.desktop} {
        padding: 30px 0 0;
        align-items: end;
    }
`;

export const ModalTime = styled.div`
    ${Text.Mobile.heading2};
    text-align: center;
    color: ${cssVars.fg_gray1_white};
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    width: 67%;
    margin: 0 auto;

    ${Screen.desktop} {
        width: 50%;
    }
`;

export const ModalTimes = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43px;
    height: 46px;
    background: ${cssVars.bg_ash_dark2};
    color: ${cssVars.fg_gray2_gray3};
    border-radius: ${radius.small};

    ${Screen.ipad} {
        width: 65px;
        height: 70px;
    }

    ${Screen.desktop} {
        width: 61px;
        height: 60px;
    }
`;

export const ModalText = styled.div`
    ${Text.Mobile.normal.regular};
    color: ${cssVars.fg_gray1_white};
    margin-top: 3px;

    ${Screen.ipad} {
        ${Text.Ipad.medium.regular};
    }

    ${Screen.desktop} {
        ${Text.Desktop.small.regular};
        margin-top: 8px;
    }
`;

export const ModalTimeText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 67%;
    margin: 0 auto;

    ${Screen.desktop} {
        width: 50%;
    }
`;

export const CloseButtonWithIcon = styled(CloseIcon)``;

interface ICloseIcon extends DefaultTheme {
    onClick: () => void;
}

export const CloseButton = styled.div<ICloseIcon>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: ${cssVars.bg_ash_normal};
    border-radius: ${radius.huge};
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;

    ${Screen.ipad} {
        width: 40px;
        height: 40px;
    }

    ${Screen.desktop} {
        width: 36px;
        height: 36px;
    }

    &:hover {
        background: ${cssVars.bg_white_dark3};
        box-shadow: ${cssVars.shadow_card_default};
    }
`;

export const IconCart = styled(SandWatch)`
    ${Screen.ipad} {
        width: 100px;
        height: 100px;
    }
    ${Screen.desktop} {
        width: 85px;
        height: 85px;
    }
`;

export const BtnCancelWrapper = styled.div`
    display: flex;
    height: 50px;
    width: 100%;
    ${Text.Full.large.medium};

    ${Screen.ipad} {
        width: 48%;
    }

    ${Screen.desktop} {
        width: 270px;
        height: 50px;
    }
`;

export const BtnOkWrapper = styled.div`
    display: flex;
    margin: 15px 0 0;
    height: 50px;
    width: 100%;
    color: ${Palette.white.normal};
    ${Text.Full.large.medium};

    ${Screen.ipad} {
        width: 48%;
    }

    ${Screen.desktop} {
        width: 270px;
        height: 50px;
    }
`;
